<template>
  <div class="img-container">
    <div class="action-content">
      <a-space>
        <a-tooltip placement="left">
          <template slot="title"> {{ showMode === 'list' ? '列表模式' : '看图模式' }} </template>
          <a-icon class="switchIcon" type="bars" v-if="showMode === 'list'" @click="handleSwitchType('card')" />
          <a-icon class="switchIcon" type="table" v-if="showMode === 'card'" @click="handleSwitchType('list')" />
        </a-tooltip>
        <a-button class="action-btn" :loading="operateLoading" @click="batchToMyPicStore">存入我的图库</a-button>
        <a-button class="action-btn" :loading="operateLoading" @click="batchDeleteImageByIds">批量删除</a-button>
        <a-dropdown>
          <a-button class="action-btn" :loading="operateLoading">批量修改</a-button>
          <a-menu slot="overlay">
            <a-menu-item>
              <a @click="handleUpdate('attrTag')">批量修改标签</a>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
        <UploadBtnBar
          :tagList="uploadTag"
          :deal="deal"
          :brandList="brandList"
          :typeList="typeList"
          @handleAddTag="handleAddTag"
          :tagTree="tagTree"
        />
      </a-space>
    </div>
    <a-spin :spinning="spinning" size="large" tip="加载中...">
      <template v-if="showMode === 'list'">
        <a-table
          :columns="columns"
          :data-source="dataList"
          :rowKey="(record) => record.fileId"
          :row-selection="rowSelection"
          :pagination="false"
          :scroll="{ x: 1450 }"
        >
          <div slot="info" slot-scope="text, record">
            <div class="writtingPreview">
              <a-popover placement="right">
                <template slot="content">
                  <img :src="record.cmsFile.fileUrl || ''" width="320px" referrerpolicy="no-referrer" />
                </template>
                <div
                  @click="handlePicPreview(dataList.indexOf(record))"
                  class="previewLeft"
                  :style="`background-image: url('${record.cmsFile.preview || record.cmsFile.fileUrl || ''}')`"
                ></div>
              </a-popover>
              <div
                class="img-tag"
                v-if="
                  record.attrValue.dealerInfoList &&
                  record.attrValue.dealerInfoList.length > 0 &&
                  !record.attrValue.authorInfoList
                "
              >
                门店专属
              </div>
              <div class="img-tag" v-if="record.attrValue.authorInfoList">账号专属</div>
              <div class="previewRight">
                <div class="rightTitle" :title="record.cmsFile.fileTitle">
                  <a-popover>
                    <template slot="content">
                      <p>{{ record.cmsFile.fileTitle }}</p>
                    </template>
                    {{ record.cmsFile.fileTitle }}
                  </a-popover>
                </div>
                <div
                  class="rightId"
                  v-clipboard:copy="record.fileId"
                  v-clipboard:success="onCopy"
                  v-clipboard:error="onCopyError"
                >
                  <div class="rightIdL">ID：{{ record.fileId || '-' }}</div>
                  <a-icon v-show="record.fileId" type="copy" />
                </div>
              </div>
            </div>
          </div>
          <div slot="attrTag" slot-scope="text, record">
            <a-tooltip overlayClassName="tool-tip-color" v-if="record.attrValue.tagInfoList">
              <div slot="title" v-if="record.attrValue.tagInfoList.length > 2">
                <div class="tag" v-for="(item, index) in record.attrValue.tagInfoList" :key="index">
                  <div v-if="item.attrName.indexOf('>') === -1">
                    <div v-if="item.attrTagName">
                      <span>{{ item.attrTagName.replace(item.attrName, '') }}</span>
                      <span style="color: #1890ff">{{ item.attrName }}</span>
                    </div>
                    <div v-else>
                      <span>{{ item.attrName }}</span>
                    </div>
                  </div>
                  <div v-else>
                    <div>
                      <span>{{
                        item.attrTagName.replace(
                          item.attrTagName.split('>')[item.attrTagName.split('>').length - 1],
                          ''
                        )
                      }}</span>
                      <span style="color: #1890ff">{{
                        item.attrTagName.split('>')[item.attrTagName.split('>').length - 1]
                      }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="tag">
                <div v-if="record.attrValue.tagInfoList[0].attrName.indexOf('>') === -1">
                  <div v-if="record.attrValue.tagInfoList[0].attrTagName">
                    <span>{{
                      record.attrValue.tagInfoList[0].attrTagName.replace(record.attrValue.tagInfoList[0].attrName, '')
                    }}</span>
                    <span style="color: #1890ff">{{ record.attrValue.tagInfoList[0].attrName }}</span>
                  </div>
                  <div v-else>
                    <span>{{ record.attrValue.tagInfoList[0].attrName }}</span>
                  </div>
                </div>
                <div v-else>
                  <div>
                    <span>{{
                      record.attrValue.tagInfoList[0].attrTagName.replace(
                        record.attrValue.tagInfoList[0].attrTagName.split('>')[
                          record.attrValue.tagInfoList[0].attrTagName.split('>').length - 1
                        ],
                        ''
                      )
                    }}</span>
                    <span style="color: #1890ff">{{
                      record.attrValue.tagInfoList[0].attrTagName.split('>')[
                        record.attrValue.tagInfoList[0].attrTagName.split('>').length - 1
                      ]
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="tag" v-if="record.attrValue.tagInfoList[1]">
                <div v-if="record.attrValue.tagInfoList[1].attrName.indexOf('>') === -1">
                  <div v-if="record.attrValue.tagInfoList[1].attrTagName">
                    <span>{{
                      record.attrValue.tagInfoList[1].attrTagName.replace(record.attrValue.tagInfoList[1].attrName, '')
                    }}</span>
                    <span style="color: #1890ff">{{ record.attrValue.tagInfoList[1].attrName }}</span>
                  </div>
                  <div v-else>
                    <span>{{ record.attrValue.tagInfoList[1].attrName }}</span>
                  </div>
                </div>
                <div v-else>
                  <div>
                    <span>{{
                      record.attrValue.tagInfoList[1].attrTagName.replace(
                        record.attrValue.tagInfoList[1].attrTagName.split('>')[
                          record.attrValue.tagInfoList[1].attrTagName.split('>').length - 1
                        ],
                        ''
                      )
                    }}</span>
                    <span style="color: #1890ff">{{
                      record.attrValue.tagInfoList[1].attrTagName.split('>')[
                        record.attrValue.tagInfoList[1].attrTagName.split('>').length - 1
                      ]
                    }}</span>
                  </div>
                </div>
              </div>
              <div v-if="record.attrValue.tagInfoList">
                <a-tag color="blue" v-if="record.attrValue.tagInfoList.length > 2"
                  >+{{ record.attrValue.tagInfoList.length - 2 }}</a-tag
                >
              </div>
            </a-tooltip>
            <div v-else>-</div>
          </div>
          <div slot="writtingTheme" slot-scope="text, record">
            <a-tooltip v-if="record.selectionTagBriefList">
              <div slot="title" v-if="record.selectionTagBriefList.length > 1">
                <a-tag
                  style="margin-bottom: 4px"
                  :color="item.colorHex"
                  v-for="(item, index) in record.selectionTagBriefList"
                  :key="index"
                >
                  {{ item.tagName }}
                </a-tag>
              </div>
              <a-tag
                style="margin-bottom: 4px"
                :color="record.selectionTagBriefList[0].colorHex"
                v-if="record.selectionTagBriefList.length > 0"
              >
                {{ record.selectionTagBriefList[0].tagName }}
              </a-tag>
              <a-tag color="blue" v-if="record.selectionTagBriefList.length > 1"
                >+{{ record.selectionTagBriefList.length - 1 }}</a-tag
              >
            </a-tooltip>
            <div v-else>-</div>
          </div>
          <div slot="principalInfoList" slot-scope="text, record">
            <div v-if="record.attrValue.principalInfoList">
              <div v-for="(item, index) in record.attrValue.principalInfoList" :key="index">
                {{ item.attrName }}
              </div>
            </div>
            <div v-else><span>-</span></div>
          </div>
          <div slot="vehicleSeriesInfoList" slot-scope="text, record">
            <div v-if="record.attrValue.vehicleSeriesInfoList">
              <div v-for="(item, index) in record.attrValue.vehicleSeriesInfoList" :key="index">
                {{ item.attrName }}
              </div>
            </div>
            <div v-else><span>-</span></div>
          </div>
          <div slot="operation" slot-scope="text, record">
            <a @click="handlePicTagEdit(record)">修改标签</a>
            <a-divider type="vertical" />
            <a @click="jumpEditImage(record)">编辑图片</a>
            <a-divider type="vertical" />
            <a-dropdown>
              <a class="ant-dropdown-link" @click="(e) => e.preventDefault()"> 更多 <a-icon type="down" /> </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a @click="singleToMyPicStore(record)">存入我的图库</a>
                </a-menu-item>
                <a-menu-item>
                  <a-popconfirm title="确定删除该图片吗?" @confirm="handleDeletePicture(record)">
                    <a>删除</a>
                  </a-popconfirm>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
          </div>
        </a-table>
      </template>
      <template v-if="showMode === 'card'">
        <div class="container-box">
          <a-row type="flex">
            <a-col class="pic-item" v-for="(val, ind) in dataList" :key="val.fileId">
              <div>
                <div class="tag-box">
                  <!-- 标签 -->
                  <a-tag style="margin-bottom: 5px" color="green" v-show="val.cmsFile.editorCount > 0"> 已使用 </a-tag>
                  <div
                    class="card-img-tag"
                    v-if="
                      val.attrValue.dealerInfoList &&
                      val.attrValue.dealerInfoList.length > 0 &&
                      !val.attrValue.authorInfoList
                    "
                  >
                    门店专属
                  </div>
                  <div class="card-img-tag" style="top: 27px" v-if="val.attrValue.authorInfoList">账号专属</div>
                </div>
                <!-- 选择框 -->
                <div :class="['checkbox', imgCheckIdList.filter((v) => v === val.fileId).length > 0 ? 'show' : '']">
                  <a-checkbox
                    :value="val"
                    :checked="imgCheckIdList.filter((v) => v === val.fileId).length > 0"
                    @change="(e) => handleCheckImg(e, val)"
                  ></a-checkbox>
                </div>
                <!-- 图片 -->
                <div
                  class="img-top"
                  :style="{ 'background-image': `url(${val.cmsFile.coverUrl})` }"
                  @click="handlePicPreview(ind)"
                ></div>
                <div class="img-bottom">
                  <div class="img-title" :title="val.fileName.split('.')[0]">{{ val.fileName.split('.')[0] }}</div>
                  <div class="img-tags">
                    <div class="tag-txt" :title="arrayToString(val.attrValue.tagInfoList)">
                      {{ arrayToString(val.attrValue.tagInfoList) }}
                    </div>
                    <div class="show-more" @click="handlePicTagEdit(val)">修改</div>
                  </div>
                  <div style="color: #c0c0c0">{{ val.ctime }}</div>
                </div>
                <div class="btn-line">
                  <span style="display: inline-block; width: 8px"></span>
                  <a-tooltip title="下载">
                    <a-icon type="download" @click="handleDownload(val.cmsFile.fileUrl, val.cmsFile.fileTitle)" />
                  </a-tooltip>
                  <a-divider type="vertical" />
                  <a-tooltip title="编辑">
                    <a-icon type="edit" @click="jumpEditImage(val)" />
                  </a-tooltip>
                  <a-divider type="vertical" />
                  <a-tooltip title="已发布至">
                    <a-popover v-model="val.linkVisible" title="已使用" trigger="click">
                      <div slot="content">
                        <a-spin :spinning="val.noteLoading">
                          <div class="note-content">
                            <div
                              class="note-item"
                              v-for="(note, index) in val.noteList"
                              :key="index"
                              @click="handleToNote(note.shareLink)"
                            >
                              <div class="note-user">
                                <img :src="note.avatar" alt="头像" referrerpolicy="no-referrer" />
                                <div class="note-user-text">
                                  <span>{{ note.nickname }}</span>
                                  <div class="note-user-authorId">
                                    <img src="@/assets/icon/xhs_logo.png" v-show="note.authorId" alt="" />
                                    <div>{{ note.authorId || '-' }}</div>
                                  </div>
                                </div>
                              </div>
                              <div>{{ note.noteTitle }}</div>
                            </div>
                          </div>
                        </a-spin>
                      </div>
                      <a-icon type="link" @click="handleGetNoteList(ind)" />
                    </a-popover>
                  </a-tooltip>
                  <a-divider type="vertical" />
                  <a-tooltip title="删除">
                    <a-popconfirm title="确定删除该图片吗?" @confirm="handleDeletePicture(val)">
                      <a-icon type="delete" @click="() => {}" />
                    </a-popconfirm>
                  </a-tooltip>
                  <a-divider type="vertical" />
                  <a-tooltip title="存入我的图库">
                    <a-icon type="vertical-align-top" @click="singleToMyPicStore(val)" />
                  </a-tooltip>
                  <span style="display: inline-block; width: 8px"></span>
                </div>
              </div>
            </a-col>
          </a-row>
        </div>
      </template>
      <base-pagination
        :currentPage="pagination.current"
        :pageSize="pagination.pageSize"
        :options="['20', '50', '100']"
        :total="totalPage"
        @onChange="handlePaginationChange"
        @onShowSizeChange="handlePaginationChange"
      />
    </a-spin>
    <!-- 修改选题 -->
    <a-modal title="编辑选题" v-model="writingThemeVisible" @cancel="handleWritingThemeCancel">
      <template slot="footer">
        <a-button style="margin-right: 15px" @click="handleWritingThemeCancel">取消</a-button>
        <a-button type="primary" :loading="editLoading" @click="handleWritingThemeOk">确认</a-button>
      </template>
      <TopicTreeInput
        :isAll="isAll"
        show-search
        style="width: 100%"
        v-model="topicSelectionTagIdList"
        :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
        :replaceFields="{ title: 'name' }"
        placeholder="选题"
        allow-clear
        multiple
        tree-default-expand-all
        :maxTagCount="3"
      />
    </a-modal>
    <!-- 批量修改 -->
    <a-modal width="700px" v-model="updateVisible" @cancel="handleUpdateCancel">
      <template slot="footer">
        <a-button style="margin-right: 15px" @click="handleUpdateCancel">取消</a-button>
        <a-button type="primary" :loading="editLoading" @click="handleUpdateOk">确认</a-button>
      </template>
      <template slot="title">
        <div>批量修改<span v-if="updateType === 'writtingTheme'">选题</span><span v-else>标签</span></div>
      </template>
      <div style="margin-bottom: 10px">
        共<span style="color: red"> {{ selectedRowKeys.length }} </span>张图片
      </div>
      <a-form-model
        :rules="rules"
        ref="form"
        :model="updateForm"
        labelAlign="left"
        :labelCol="{ xl: 4 }"
        :wrapperCol="{ span: 18 }"
      >
        <a-form-model-item label="修改方式" required prop="relationResetType">
          <a-select v-model="updateForm.relationResetType" placeholder="请选择修改方式">
            <a-select-option value="UPDATE"> 批量新增 </a-select-option>
            <a-select-option value="DELETE"> 批量删除 </a-select-option>
          </a-select>
          <div v-if="updateForm.relationResetType" style="color: #cccccc; position: relative; top: -9px">
            不改变图片原本<span v-if="updateType === 'writtingTheme'">选题</span><span v-else>标签</span>，仅在原来<span
              v-if="updateType === 'writtingTheme'"
              >选题</span
            ><span v-else>标签</span>上<span v-if="updateForm.relationResetType === 'UPDATE'">新增</span
            ><span v-else>删除</span>
          </div>
        </a-form-model-item>
        <a-form-model-item required prop="tagIdList" v-if="updateType === 'writtingTheme'" label="选题">
          <TopicTreeInput
            :isAll="isAll"
            @change="handelTree"
            show-search
            style="width: 100%"
            v-model="updateForm.tagIdList"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :replaceFields="{ title: 'name' }"
            placeholder="选题"
            allow-clear
            multiple
            tree-default-expand-all
            :maxTagCount="3"
          />
        </a-form-model-item>
        <div v-else>
          <div class="tag-line">
            <div class="tag-title"><span class="red">*</span>标签：</div>
            <div class="tag-line">
              <template>
                <CascaderMenu
                  ref="cascaderMenu"
                  :option="item"
                  v-for="(item, index) in tagTree"
                  :key="index"
                  @change="(props) => handleTagChange(props, index)"
                />
              </template>
            </div>
          </div>
          <div class="bottom">
            <div style="width: 45px px; flex-shrink: 0">已选：</div>
            <div class="tag-list">
              <div class="tags" v-for="value in tagList" :key="value.id">
                <span>{{ value.parentText }}</span
                ><span style="color: #1890ff">{{ value.text }}</span>
                <span>
                  <a-icon type="close" style="margin-left: 5px; cursor: pointer" @click="handleTagDel(value)" />
                </span>
              </div>
            </div>
          </div>
        </div>
      </a-form-model>
    </a-modal>
    <!--查看图片-->
    <preview-modal
      title="预览"
      :visible="preview_visible"
      :preview-src="preview_src"
      width="50%"
      @cancel="handlePreviewCancel"
    >
      <template #content>
        <div style="margin-top: 20px; text-align: center">
          图片像素: {{ previewData.width }}x{{ previewData.height }}
        </div>
      </template>
    </preview-modal>

    <batch-push-to-topic-modal ref="BatchPushToTopicModal"></batch-push-to-topic-modal>
  </div>
</template>

<script>
import previewModal from '@/components/PreviewModal/previewModal';
import api from '@/api/xhsAgencyApi';
import BatchPushToTopicModal from '@/components/xhsAgencyTool/BatchPushToTopicModal.vue';
import { api as viewerApi } from 'v-viewer';
import TopicTreeInput from '@/components/xhsAgencyTool/TopicTreeInput';
import UploadBtnBar from './UploadBtnBar';
import CascaderMenu from '@/components/CascaderMenu.vue';
import { trackRequest } from '@/utils/track';

export default {
  props: {
    tagTree: {
      type: Array,
      default: () => [],
    },
    brandList: {
      type: Array,
      default: () => [],
    },
    pagination: {
      default: {
        current: 1,
        pageSize: 20,
      },
    },
    spinning: {
      type: Boolean,
      default: false,
    },
    totalPage: {
      default: 0,
    },
    dataList: {
      default: () => [],
    },
  },
  components: { CascaderMenu, UploadBtnBar, TopicTreeInput, previewModal, BatchPushToTopicModal },
  data() {
    const columns = [
      {
        title: '基本信息',
        width: 400,
        scopedSlots: { customRender: 'info' },
      },
      {
        title: '图片标签',
        width: 300,
        align: 'center',
        scopedSlots: { customRender: 'attrTag' },
      },
      {
        title: '上传时间',
        align: 'center',
        width: 200,
        dataIndex: 'ctime',
        customRender: (text) => {
          return text || '-';
        },
      },
      {
        title: '品牌',
        width: 150,
        align: 'center',
        scopedSlots: { customRender: 'principalInfoList' },
      },
      {
        title: '车系',
        width: 150,
        align: 'center',
        scopedSlots: { customRender: 'vehicleSeriesInfoList' },
      },
      {
        align: 'center',
        title: '操作',
        // width: 300,
        fixed: 'right',
        scopedSlots: { customRender: 'operation' },
      },
    ];
    return {
      editLoading: false,
      fileId: '',
      isAll: true,
      rules: {
        relationResetType: [{ required: true, message: '请选择修改方式', trigger: 'change' }],
        tagIdList: [{ required: true, message: '请选择选题', trigger: 'change' }],
      },
      tagList: [],
      updateForm: {
        relationResetType: undefined,
        tagIdList: [],
      },
      updateVisible: false,
      updateType: '',
      typeList: [],
      deal: undefined, // 0 - AIGC 1 - 已处理
      uploadTag: [],
      writingThemeVisible: false,
      topicSelectionTagIdList: [],
      selectedRowKeys: [],
      columns,
      preview_visible: false,
      preview_src: '',
      previewData: {
        width: '',
        height: '',
      },
      operateLoading: false,
      imgCheckIdList: [],
      showMode: 'list',
    };
  },
  mounted() {
    const showMode = localStorage.getItem('imgShowMode');
    if (!showMode) {
      localStorage.setItem('imgShowMode', 'list');
    } else {
      this.showMode = showMode;
    }
  },
  methods: {
    handelTree(value) {
      this.updateForm.tagIdList = value;
    },
    handleTagChange(value, compIndex) {
      if (value.isAdd) {
        const treeData = this.tagTree[compIndex];
        const parentText = `${treeData.name} > ${value.parent.name} > `;
        const text = value.self.name;
        this.tagList.push({
          id: value.self.id,
          parentText,
          text,
          tagPath: value.self.tagPath,
          compIndex,
        });
      } else {
        const index = this.tagList.indexOf((ele) => ele.id === value.self.id);
        this.tagList.splice(index, 1);
      }
    },
    handleTagDel(value) {
      this.$refs.cascaderMenu[value.compIndex].delSelect(value.tagPath);
      let tagIndex = -1;
      this.tagList.forEach((ele, index) => {
        if (ele.id == value.id) {
          tagIndex = index;
        }
      });
      this.tagList.splice(tagIndex, 1);
    },
    handleUpdateCancel() {
      this.updateVisible = false;
      this.$refs.form.resetFields();
    },
    handleUpdateOk() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          const selectedRowKeys = this.showMode === 'list' ? this.selectedRowKeys : this.imgCheckIdList;
          const eName = this.updateType === 'attrTag' ? 'edit_image_tag' : 'edit_image_topic';
          this.handleTrack(eName, {
            image_ids: selectedRowKeys,
          });

          if (this.tagList.length === 0 && this.updateType === 'attrTag') {
            this.$message.error('请选择标签');
          } else {
            const data = {
              relationResetType: this.updateForm.relationResetType,
              objectIdList: selectedRowKeys,
              tagList: this.tagList.map((item) => {
                return {
                  id: item.id,
                  tagName: `${item.parentText}${item.text}`,
                };
              }),
            };
            const data2 = {
              ...this.updateForm,
              objectIdList: selectedRowKeys,
              relationType: 'IMAGE',
            };
            this.editLoading = true;
            try {
              const { code, message } =
                this.updateType === 'attrTag'
                  ? await api.batchEditTagApi(data)
                  : await api.batchEditSelectionTagApi(data2);
              if (code == 200) {
                this.updateVisible = false;
                this.editLoading = false;
                this.getDataList();
                this.$message.success('修改成功');
                this.selectedRowKeys = [];
                this.imgCheckIdList = [];
              } else {
                this.updateVisible = false;
                this.editLoading = false;
                return this.$message.error(`${message}`);
              }
            } catch (e) {
              this.updateVisible = false;
              this.editLoading = false;
            }
          }
        }
      });
    },
    handleUpdate(type) {
      const selectedRowKeys = this.showMode === 'list' ? this.selectedRowKeys : this.imgCheckIdList;
      if (!selectedRowKeys.length) return this.$message.info('请至少选择一条图片');
      this.updateForm = {
        relationResetType: undefined,
        tagIdList: [],
      };
      this.tagList = [];
      if (this.updateType === 'attrTag') {
        this.$refs.cascaderMenu.forEach((val) => {
          val.clearAll();
        });
      }
      this.updateType = type;
      this.updateVisible = true;
    },
    getDataList() {
      this.selectedRowKeys = [];
      this.imgCheckIdList = [];
      this.$emit('getDataList');
    },
    handleAddTag(val) {
      this.uploadTag.unshift({
        ...val,
        isChoose: false,
      });
    },
    handleWritingThemeCancel() {
      this.writingThemeVisible = false;
      this.topicSelectionTagIdList = [];
      this.fileId = '';
    },
    async handleWritingThemeOk() {
      // if (this.topicSelectionTagIdList.length === 0) return this.$message.info('请至少选择一个选题');
      const data = {
        selectionTagList: this.topicSelectionTagIdList,
      };
      this.editLoading = true;
      try {
        const { code, message } = await api
          .editSelectionTagApi(data, this.fileId)
          .finally(() => (this.editLoading = false));
        if (code === 200) {
          this.$message.success('修改成功');
          this.getDataList();
        } else {
          return this.$message.error(`${message}`);
        }
        this.handleWritingThemeCancel();
      } catch (e) {
        this.editLoading = false;
      }
    },
    onCopy() {
      this.$message.success('复制成功');
    },
    onCopyError() {
      this.$message.error('复制失败，该浏览器不支持自动复制');
    },
    handlePaginationChange(current, pageSize) {
      this.selectedRowKeys = [];
      this.imgCheckIdList = [];
      this.$emit('getList', { pageSize, current });
    },
    editRecord(index) {
      const { editorCount } = this.dataList[index].cmsFile;
      const { fileId } = this.dataList[index];
      const apiName = editorCount ? 'handleCancelEditRecord' : 'handleEditRecord';
      this.$parent.handleRequest(
        apiName,
        () => {
          this.$message.success(`${editorCount ? '取消标记' : '标记为已使用'}成功`);
          this.$emit(
            'update:dataList',
            this.dataList.forEach((value) => {
              if (value.fileId === fileId) {
                value.cmsFile.editorCount = editorCount ? 0 : 1;
              }
            })
          );
        },
        fileId
      );
    },
    // 处理下载
    handleDownload(url, title) {
      if (!url) return this.$message.error('图片为空');
      const imgUrl = `${url}?time=${Date.now()}`;
      const hide = this.$message.loading('正在获取图片...', 0);
      fetch(imgUrl)
        .then(async (res) => await res.blob())
        .then((blob) => {
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = URL.createObjectURL(blob);
          a.download = title;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        })
        .catch(() => {
          // 获取图片失败
          window.open(url, '_blank');
        })
        .finally(() => hide());
    },
    // 打开大图
    async openBigImg(url) {
      if (!url) {
        return this.$message.error('URL为空，不支持查看！');
      }
      this.width = '480px';
      this.preview_visible = true;
      this.preview_src = url;
      let size = await this.getImageSize(url);
      this.previewData = {
        width: size.width,
        height: size.height,
      };
    },
    getImageSize(url) {
      return new Promise(function (resolve, reject) {
        let image = new Image();
        image.onload = function () {
          resolve({
            width: image.width,
            height: image.height,
          });
        };
        image.onerror = function () {
          reject(new Error('error'));
        };
        image.src = url;
      });
    },
    handlePreviewCancel() {
      this.preview_src = '';
      this.preview_visible = false;
    },
    arrayToString(val) {
      if (val?.length) {
        let strArr = [];
        val.forEach((value) => {
          strArr.push(value.attrTagName ? value.attrTagName : value.attrName);
        });
        return strArr.reduce((prev, item) => {
          prev += `、${item}`;
          return prev;
        });
      } else {
        return val;
      }
    },
    /**
     * 处理标记
     * @param {number} index 列表索引
     */
    handleMark(index) {
      this.editRecord(index);
    },
    /**
     * 获取已使用笔记列表
     * @param {number} index 索引
     */
    handleGetNoteList(index) {
      this.$emit('handleGetNoteList', index);
    },
    /**
     * 删除图片
     * @param {number} index 索引
     */
    handleDeletePicture(val) {
      this.imgCheckIdList = [];
      this.selectedRowKeys = [];
      this.$emit('handleDeletePicture', val);
    },
    /**
     * 跳转笔记链接
     * @param {number} index 索引
     */
    handleToNote(index) {
      this.$emit('handleToNote', index);
    },
    handlePicTagEdit(value) {
      this.$emit('editPicTag', value);
    },

    // 图片预览
    handlePicPreview(index) {
      const $viewer = this.$viewerApi({
        options: {
          toolbar: true,
          initialViewIndex: index,
        },
        images: this.dataList.map((val) => val.cmsFile.fileUrl),
      });
    },

    handleCheckImg(e, { fileId }) {
      if (e.target.checked) {
        this.imgCheckIdList.push(fileId);
      } else {
        let index = this.imgCheckIdList.findIndex((item) => item === fileId);
        this.imgCheckIdList.splice(index, 1);
      }
    },

    jumpEditImage(val) {
      this.handleTrack('edit_image', { image_ids: val.fileId });
      let imageUrlList = [];
      imageUrlList.push(val.cmsFile.fileUrl);
      api.saveImageList({ configType: 'imageEdit', data: { imageUrlList } }).then((res) => {
        if (res.code == 200) {
          window.open(`${process.env.VUE_APP_TEMPLATE_EDITOR}?localId=${res.data}`, '_blank');
        } else {
          this.$message.error(`提交图片数据失败，${res.message}`);
        }
      });
    },
    // 存入我的图库
    batchToMyPicStore() {
      const selectedRowKeys = this.showMode === 'list' ? this.selectedRowKeys : this.imgCheckIdList;
      if (!selectedRowKeys.length) return this.$message.info('请至少选择一张图片');
      const params = [];
      this.dataList.forEach((img) => {
        selectedRowKeys.forEach((select) => {
          if (img.fileId === select) {
            params.push({
              fileId: img.fileId,
              url: img.cmsFile.fileUrl,
            });
          }
        });
      });
      this.operateLoading = true;
      api
        .handleBatchToMyPicStore(params)
        .then(({ code, message }) => {
          if (code === 200) {
            this.selectedRowKeys = [];
            this.imgCheckIdList = [];
            this.$message.success('操作成功');
          } else {
            this.$message.error(message);
          }
        })
        .finally(() => (this.operateLoading = false));
    },
    singleToMyPicStore(data) {
      const params = [
        {
          fileId: data.fileId,
          url: data.cmsFile.fileUrl,
        },
      ];
      api.handleBatchToMyPicStore(params).then(({ code, message }) => {
        if (code === 200) {
          this.$message.success('操作成功');
        } else {
          this.$message.error(message);
        }
      });
    },
    //批量删除图片
    batchDeleteImageByIds() {
      const selectedRowKeys = this.showMode === 'list' ? this.selectedRowKeys : this.imgCheckIdList;
      if (!selectedRowKeys.length) return this.$message.info('请至少选择一张图片');
      this.handleTrack('delete_image', {
        image_ids: selectedRowKeys,
      });

      let that = this;
      this.$confirm({
        title: `共计${selectedRowKeys.length}张图片，请确认是否删除?`,
        okText: '确定',
        cancelText: '取消',
        onOk() {
          that.operateLoading = true;
          api.handleDeletePicture(selectedRowKeys).then((res) => {
            that.operateLoading = false;
            if (res.code == 200) {
              that.$emit('getDataList');
              that.selectedRowKeys = [];
              that.imgCheckIdList = [];
              that.$message.success('删除成功');
            } else {
              that.$message.error(`删除失败，${res.message}`);
            }
          });
        },
      });
    },

    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    async handleRequest(APINAME, callbackFn, PARAMS = null) {
      const { code, data, message } = await api[APINAME](PARAMS);
      if (code === 200) {
        callbackFn(data);
      } else {
        return this.$message.error(`${message}`);
      }
    },
    handleTrack(trackEvent, trackParams = {}) {
      const to = this.$route;
      trackRequest(to, trackEvent, trackParams);
    },
    handleSwitchType(type) {
      this.selectedRowKeys = [];
      this.imgCheckIdList = [];
      this.showMode = type;
      localStorage.setItem('imgShowMode', type);
      this.$emit('getList', { pageSize: this.pagination.pageSize, current: 1 });
    },
  },
  computed: {
    rowSelection() {
      const { selectedRowKeys } = this;
      return {
        selectedRowKeys,
        onChange: this.onSelectChange,
      };
    },
  },
};
</script>

<style lang="less" scoped>
.img-container {
  position: relative;
}

.action-content {
  position: absolute;
  right: 0;
  top: -60px;
  display: flex;
  justify-content: space-between;
}

.tag-line {
  display: flex;

  .tag-title {
    padding-top: 5px;
    width: 80px;
    color: #262626;
    .red {
      display: inline-block;
      margin-right: 4px;
      color: #f5222d;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
    }
  }
}

.tag {
  margin: 0 5px 5px 0;
  padding: 0 5px;
  border: 1px solid #1890ff;
  border-radius: 3px;
  color: #80c2ff;
  user-select: none;
}

.bottom {
  display: flex;
  margin-top: 10px;
  .tag-list {
    display: flex;
    flex-wrap: wrap;

    .tags {
      margin: 0 5px 5px 0;
      padding: 0 5px;
      border: 1px solid #1890ff;
      border-radius: 3px;
      color: #80c2ff;
      user-select: none;
    }
  }
}

.writtingPreview {
  display: flex;
  cursor: pointer;
  position: relative;
  .big-box div {
    display: none;
    //这个是包着图片的大盒子
    width: 311px;
    height: 175px;
    border-radius: 5px 5px 0 0;
    background-color: #333333;
    overflow: hidden; //隐藏超出的部分
  }
  .big-box div:hover {
    cursor: pointer; //经过页面时，鼠标会变成一只手
    display: block;
  }

  .big-box img {
    width: 100%;
    height: 100%;
    background-color: #f9b008;
    transform: scale(1.1); //原本的图片的大小，图片原来的大小不变
    transition: all 0.6s;
  }

  .big-box img:hover {
    transform: scale(1.2); //图片按照比例，整体放大了1.2倍
    // 当鼠标经过是图片放大的倍数为1.2倍
  }
  .previewLeft {
    margin-right: 5px;
    width: 80px;
    height: 100px;
    border-radius: 10px;
    background-size: cover;
    background-position: center;
  }

  .previewRight {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .rightTitle {
      width: 280px;
      font-size: 600;
      color: #000;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .rightId {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #ae7983;

      .rightIdL {
        width: 265px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
}

.container-box {
  display: block;
  min-width: 845px;

  .pic-item {
    box-shadow: 1px 1px 4px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    border: 1px solid #e9e9e9;
    margin: 0 .6% 18px;
    width: 18.7%;
    // padding-bottom: 10px;
    background-color: #fff;

    .img-top {
      position: relative;
      width: 100%;
      height: 180px;
      background-repeat: no-repeat;
      // background-size: 100% 100%;
      cursor: pointer;
      background-size: cover;
      background-position: top center;
    }

    .img-bottom {
      padding: 10px 20px;

      .img-title {
        font-weight: 600;
        width: 150px;
        white-space: nowrap;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .img-tags {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 8px 0;

      .tag-txt {
        width: 80%;
        white-space: nowrap;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        color: #a4a4a4;
      }

      .show-more {
        width: 28px;
        height: 20px;
        line-height: 20px;
        color: #1890ff;
        cursor: pointer;
      }
    }

    .btn-line {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-top: 1px solid rgb(233, 233, 233);
      padding: 10px 0;
      background: #f2f4f5;
    }
  }

  .checkbox {
    position: absolute;
    top: 1px;
    right: 0;
    line-height: 0;
    z-index: 2;
    /deep/ .ant-checkbox-inner {
      width: 24px;
      height: 24px;
    }
    /deep/ .ant-checkbox-inner::after {
      width: 8px;
      height: 14px;
    }
  }
}

// 笔记列表
.note-content {
  max-height: 300px;
  min-height: 100px;
  overflow-y: auto;
}

.note-item {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 300px;
  padding: 10px;
  background: rgb(236, 236, 236);
  cursor: pointer;
  & + .note-item {
    margin-top: 10px;
  }

  .note-user {
    display: flex;
    margin-bottom: 8px;
    & > img {
      width: 50px;
      height: 50px;
      border-radius: 25px;
      margin-right: 8px;
    }
    .note-user-text {
      display: flex;
      flex-direction: column;
    }
    .note-user-authorId {
      display: flex;
      margin-top: 4px;
      & > img {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        overflow: hidden;
        margin-right: 8px;
      }
    }
  }
}

.tag-box {
  position: absolute;
  left: 3px;
  top: 3px;
  z-index: 2;
  border-radius: 0;
  &:hover {
    opacity: 1;
  }
}

.img-tag {
  position: absolute;
  top: 2px;
  left: 2px;
  border-radius: 3px;
  color: #fa8c16;
  background: #fff7e6;
  border-color: #ffd591;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  z-index: 2;
}

.card-img-tag {
  border-radius: 3px;
  color: #fa8c16;
  background: #fff7e6;
  border-color: #ffd591;
  padding: 0 7px;
  font-size: 12px;
  line-height: 20px;
  white-space: nowrap;
  z-index: 2;
}

.switchIcon {
  font-weight: bolder;
  font-size: 25px;
  height: 25px;
  line-height: 25px;
  color: #7f7f7f;
  cursor: pointer;
}
</style>
<style lang="scss">
.tool-tip-color {
  .ant-tooltip-inner {
    background-color: #fff !important;
  }
}
</style>
