<template>
  <a-modal v-model="visible" @cancel="onClose" width="700px" centered>
    <div slot="title">
      <span>编辑标签</span>
      <span class="sub-title">删除标签不会删除图片</span>
    </div>
    <!-- <a-select
      mode="multiple"
      v-model="currentTagList"
      placeholder="请选择"
      show-search
      option-filter-prop="children"
      allowClear
      style="width: 650px"
    >
      <a-select-option v-for="item in allTagList" :key="item.id">{{ item.tagName }}</a-select-option>
    </a-select> -->
    <div style="display: flex">
      <div class="tag-head">标签：</div>
      <div class="tag-line">
        <template v-if="visible">
          <CascaderMenu
            ref="cascaderMenu"
            :option="item"
            v-for="(item, index) in tagTree"
            :key="index"
            @change="(props) => handleTagChange(props, index)"
          />
        </template>
      </div>
    </div>
    <div class="bottom">
      <div style="width: 45px px; flex-shrink: 0">已选：</div>
      <div class="tag-list">
        <div class="tags" v-for="value in tagList" :key="value.id">
          <span>{{ value.parentText }}</span
          ><span style="color: #1890ff">{{ value.text }}</span>
          <span>
            <a-icon type="close" style="margin-left: 5px; cursor: pointer" @click="handleTagDel(value)" />
          </span>
        </div>
      </div>
    </div>
    <template slot="footer">
      <a-button style="margin-right: 15px" @click="onClose">取消</a-button>
      <a-button type="primary" :loading="saveLoading" @click="handleTagSave">确认</a-button>
    </template>
  </a-modal>
</template>

<script>
import _ from 'lodash';
import api from '@/api/xhsAgencyApi';
import CascaderMenu from '@/components/CascaderMenu.vue';

export default {
  components: { CascaderMenu },
  props: {
    // allTagList: {
    //   default: [],
    // },
    tagTree: {
      default: [],
    },
  },
  data() {
    return {
      visible: false,
      currentTagList: [],
      editId: undefined,
      saveLoading: false,
      tagList: [],
    };
  },
  methods: {
    openModal(editData) {
      this.visible = true;
      this.editId = editData.fileId;
      editData.attrValue.tagInfoList.map((tag) => {
        if (!tag.attrTagName) return;
        const arr = tag.attrTagPath.split('/').filter((val) => val);
        const compIndex = this.tagTree.findIndex((val) => val.id === arr[0]);
        const texts = tag.attrTagName.split('>');
        const parentText = `${texts[0]} > ${texts[1]} > `;
        this.tagList.push({
          id: tag.attrValue,
          parentText,
          text: tag.attrTagName.indexOf('>')===-1 ? tag.attrName : tag.attrTagName.split('>')[tag.attrTagName.split('>').length-1],
          tagPath: tag.attrTagPath,
          compIndex,
        });
        setTimeout(() => {
          this.$refs.cascaderMenu[compIndex].selectedValue.push(tag.attrTagPath);
        }, 100);
      });
    },
    async handleTagSave() {
      const list = this.tagList.map((val) => ({
        id: val.id,
        tagName: val.text,
      }));
      if (!list.length) return this.$message.info('至少选择一个标签');
      this.saveLoading = true;
      const { code, message } = await api
        .handleUpdateFileTag(this.editId, list)
        .finally(() => (this.saveLoading = false));
      if (code === 200) {
        this.editId = undefined;
        this.onClose();
        this.$message.success('修改成功')
        this.$parent.getDataList();
      } else {
        this.$message.error(`${message}`);
      }
    },
    handleTagChange(value, compIndex) {
      if (value.isAdd) {
        const treeData = this.tagTree[compIndex];
        const parentText = `${treeData.name} > ${value.parent.name} > `;
        const text = value.self.name;
        this.tagList.push({
          id: value.self.id,
          parentText,
          text,
          tagPath: value.self.tagPath,
          compIndex,
        });
      } else {
        const index = this.tagList.indexOf((ele) => ele.id === value.self.id);
        this.tagList.splice(index, 1);
      }
    },
    handleTagDel(value) {
      this.$refs.cascaderMenu[value.compIndex].delSelect(value.tagPath);
      let tagIndex = -1;
      this.tagList.forEach((ele, index) => {
        if (ele.id == value.id) {
          tagIndex = index;
        }
      });
      this.tagList.splice(tagIndex, 1);
    },
    onClose() {
      this.tagList = [];
      this.currentTagList = [];
      this.visible = false;
      this.editId = undefined;
    },
  },
};
</script>

<style scoped lang="scss">
.sub-title {
  margin-left: 8px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
}

.tag-head {
  height: 27px;
  line-height: 27px;
}

.tag-line {
  display: flex;
  flex-wrap: wrap;
}

.bottom {
  display: flex;
  margin-top: 10px;
}

.tag-list {
  display: flex;
  flex-wrap: wrap;

  .tags {
    margin: 0 5px 5px 0;
    padding: 0 5px;
    border: 1px solid #1890ff;
    border-radius: 3px;
    color: #80c2ff;
    user-select: none;
  }
}
</style>
