<template>
  <div class="picture-library">
    <SearchBar
      ref="searchBar"
      :brandList="brandList"
      :typeList="typeList"
      :tagList="searchTag"
      :tagTree="tagTree"
      :creator="form.creator"
      @handleBrandChange="handleBrandChange"
      @handleTagClick="handleTagClick"
      @handleTypeChange="handleTypeChange"
      @handleTabChange="handleTabChange"
      @handleDeleteTag="handleDeleteTag"
      @handleTagChange="handleTagChange"
      @handleDateChange="handleDateChange"
      @handlePushStatusChange="handlePushStatusChange"
      @handleTopicChange="handleTopicChange"
      @handleStoreChange="handleStoreChange"
      @handleAccountChange="handleAccountChange"
    />
    <!-- <UploadBtnBar
      :tagList="uploadTag"
      :deal="deal"
      :brandList="brandList"
      :typeList="typeList"
      @handleAddTag="handleAddTag"
      :tagTree="tagTree"
    /> -->
    <PictureTable
      @getDataList="getDataList"
      :brandList="brandList"
      :typeList="typeList"
      :tagTree="tagTree"
      :spinning="spinning"
      :dataList="dataList"
      :totalPage="totalPage"
      @getList="getList"
      :pagination="pagination"
      @handleGetNoteList="handleGetNoteList"
      @handleDeletePicture="handleDeletePicture"
      @handleToNote="handleToNote"
      @editPicTag="editPicTag"
    />
    <TagDeleteModal
      ref="TagDeleteModal"
      @tagListChange="handleTagListChange"
      @handleAddTag="handleAddTag"
      :treeData="tagTree"
      @refreshTagList="refreshTagList"
    />
    <PicTagEditModal ref="PicTagEditModal" :tagTree="tagTree" />
  </div>
</template>

<script>
import SearchBar from './components/SearchBar';
import PictureTable from './components/PictureTable';
// import UploadBtnBar from './components/UploadBtnBar';
import TagDeleteModal from './components/TagDeleteModal';
import PicTagEditModal from './components/PicTagEditModal';
import api from '@/api/xhsAgencyApi';
export default {
  components: { SearchBar, PictureTable, TagDeleteModal, PicTagEditModal },
  data() {
    return {
      brandList: [],
      typeList: [],
      allTagList: [],
      tagTree: [],
      searchTag: [],
      uploadTag: [],
      searchTagList: [],
      pagination: {
        current: 1,
        pageSize: 20,
      },
      deal: undefined, // 0 - AIGC 1 - 已处理
      form: {
        principalIds: [],
        vehicleSeriesIds: [],
        dealerIds: [],
        authorIds: [],
        creator: localStorage.user_name || undefined,
        editorCount: undefined,
      },
      dataList: [],
      totalPage: 0,
      spinning: false,
      showPicTagEdit: false,
    };
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      await this.getTagList();
      await this.getBrandList();
      this.getDataList();
    },
    async getDataList() {
      // const tagIds =
      //   this.searchTagList[0] === 0
      //     ? ''
      //     : this.searchTagList.reduce((prev, item) => {
      //         prev += `,${item}`;
      //         return prev;
      //       }) || '';
      const tagIds =
        this.searchTagList.length === 0
          ? ''
          : this.searchTagList.reduce((prev, item) => {
              prev += `,${item}`;
              return prev;
            }) || '';

      const principalIds =
        (this.form.principalIds.length &&
          this.form.principalIds.reduce((prev, item) => {
            prev += `,${item}`;
            return prev;
          })) ||
        '';
      const vehicleSeriesIds = this.form.vehicleSeriesIds
        ? (this.form.vehicleSeriesIds.length &&
            this.form.vehicleSeriesIds.reduce((prev, item) => {
              prev += `,${item}`;
              return prev;
            })) ||
          ''
        : '';
      const params = {
        isDir: 0,
        refCount: 1,
        pageNum: this.pagination.current,
        pageSize: this.pagination.pageSize,
        tagIds,
        principalIds,
        creator: this.form.creator || undefined,
        editorCount: this.form.editorCount,
        vehicleSeriesIds,
        endDateTime: this.form.endDateTime,
        startDateTime: this.form.startDateTime,
        selectionTagList: this.form.selectionTagList,
        pushStatus: this.form.pushStatus,
        dealerIds: this.form.dealerIds.join(',') || undefined,
        authorIds: this.form.authorIds.join(',') || undefined,
        // deal: this.deal,
      };
      !principalIds && delete params.principalIds;
      !vehicleSeriesIds && delete params.vehicleSeriesIds;
      !tagIds && delete params.tagIds;
      this.spinning = true;
      await this.handleRequest(
        'getPicList',
        (data) => {
          this.dataList = data.list.map((val) => {
            val.cmsFile.coverUrl = `${val.cmsFile.fileUrl}?x-oss-process=image/resize,l_288,m_lfit`;
            return val;
          });
          this.totalPage = data.total;
        },
        params
      ).finally(() => {
        this.spinning = false;
      });
    },
    async getBrandList() {
      await this.handleRequest('getBrandList', (data) => {
        this.brandList = data;
      });
    },
    initTagValue(data) {
      if (data.children && data.children.length > 0) {
        data.children = data.children.map(this.initTagValue);
      }
      return {
        ...data,
        name: data.tagName,
        value: data.tagPath,
        key: data.id,
      };
    },
    async getTagList() {
      await this.handleRequest('getTagTree', (data) => {
        this.tagTree = data.map(this.initTagValue);
        console.log('this.tagTree:', this.tagTree);
        // this.allTagList = data.list.map((val) => {
        //   val.isChoose = false;
        //   return val;
        // });
        // this.searchTag = JSON.parse(JSON.stringify(this.allTagList));
        // this.searchTag.unshift({ id: 0, tagName: '全部', isChoose: true });
        // this.uploadTag = JSON.parse(JSON.stringify(this.allTagList));
      });
    },
    /**
     * 删除标签
     */
    handleDeleteTag() {
      this.$refs.TagDeleteModal.openModal(this.allTagList);
    },
    handleTagListChange(list) {
      this.allTagList = list;
      this.searchTag = JSON.parse(JSON.stringify(list));
      this.searchTag.unshift({ id: 0, tagName: '全部', isChoose: false });
      this.uploadTag = JSON.parse(
        JSON.stringify(
          this.allTagList.map((val) => ({
            ...val,
            isChoose: false,
          }))
        )
      );
      this.handleTagClick(0);
    },
    async handleBrandChange(id, form) {
      this.form = form;
      this.getDataList();
      if (id.length) {
        const principalIds =
          id.reduce((prev, item) => {
            prev += `,${item}`;
            return prev;
          }) || '';
        await this.handleRequest(
          'getTypeList',
          (data) => {
            this.typeList = data.map((val) => {
              return {
                id: val.id,
                name: val.name,
              };
            });
          },
          { principalIds }
        );
      } else {
        this.typeList = [];
      }
    },
    handleTabChange() {
      // this.deal = value;
      // this.pagination.current = 1;
      // this.getDataList();
    },
    handleTagChange(value) {
      this.searchTagList = value;
      this.getDataList();
    },
    handleTypeChange(form) {
      this.form = form;
      this.pagination.current = 1;
      this.getDataList();
    },
    handleStoreChange(form) {
      this.form = form;
      this.pagination.current = 1;
      this.getDataList();
    },
    handleAccountChange(form) {
      this.form = form;
      this.pagination.current = 1;
      this.getDataList();
    },
    handleTopicChange(form) {
      this.form = form;
      this.pagination.current = 1;
      this.getDataList();
    },
    handleDateChange(form) {
      this.form = form;
      this.pagination.current = 1;
      this.getDataList();
    },
    handlePushStatusChange(form) {
      this.form = form;
      this.pagination.current = 1;
      this.getDataList();
    },
    // handleTagClick(id) {
    //   if (id === 0) {
    //     // 点击全部标签
    //     const isChooseAll = this.searchTag.find((val) => val.id === 0).isChoose;
    //     if (!isChooseAll) {
    //       // 全部选中状态
    //       this.searchTag.forEach((val) => {
    //         val.isChoose = false;
    //         if (val.id === 0) val.isChoose = true;
    //       });
    //     } else {
    //       return false;
    //     }
    //   } else {
    //     // 点击其他标签
    //     this.searchTag.forEach((val) => {
    //       // 互斥全部标签
    //       if (val.id === 0) {
    //         val.isChoose = false;
    //       }
    //       if (val.id === id) {
    //         const currentChoose = this.searchTag.filter((value) => value.isChoose);
    //         if (currentChoose.length === 1 && val.isChoose) {
    //           // 只有一个其他标签并被反选时 选中全部
    //           this.searchTag.forEach((value) => {
    //             if (value.id === 0) value.isChoose = true;
    //           });
    //         }
    //         val.isChoose = !val.isChoose;
    //       }
    //     });
    //   }
    //   this.searchTagList = [];
    //   this.searchTag.forEach((val) => {
    //     if (val.isChoose) {
    //       this.searchTagList.push(val.id);
    //     }
    //   });
    //   this.getDataList();
    // },
    handleTagClick() {
      this.getDataList();
    },
    getList(pagination) {
      this.pagination.current = pagination.current;
      this.pagination.pageSize = pagination.pageSize;
      this.getDataList();
    },
    handleAddTag(val) {
      this.allTagList.unshift(val);
      this.uploadTag.unshift({
        ...val,
        isChoose: false,
      });
      this.searchTag.splice(1, 0, {
        ...val,
        isChoose: false,
      });
    },
    async handleGetNoteList(index) {
      const { fileId } = this.dataList[index];
      this.$set(this.dataList[index], 'noteLoading', true);
      try {
        const { code, data, message } = await api.handleListNoteByFileId({ fileId });
        this.$set(this.dataList[index], 'noteLoading', false);
        if (code === 200) {
          this.$set(this.dataList[index], 'noteList', data);
        } else {
          return this.$message.error(`${message}`);
        }
      } catch (e) {
        this.$set(this.dataList[index], 'noteLoading', false);
      }
    },
    async handleDeletePicture(val) {
      // if (index === -1) {
      //   this.getDataList();
      //   return;
      // }
      const fileId = val.fileId;
      this.spinning = true;
      try {
        const { code, message } = await api.handleDeletePicture([fileId]);
        this.spinning = false;
        if (code === 200) {
          this.$message.success('删除成功');
          this.getDataList();
        } else {
          return this.$message.error(`${message}`);
        }
      } catch (e) {
        this.spinning = false;
      }
    },
    async handleToNote(shareLink) {
      window.open(shareLink);
    },
    editPicTag(value) {
      this.$refs.PicTagEditModal.openModal(value);
    },
    async handleRequest(APINAME, callbackFn, PARAMS = null) {
      const { code, data, message } = await api[APINAME](PARAMS);
      if (code === 200) {
        callbackFn(data);
      } else {
        return this.$message.error(`${message}`);
      }
    },
    refreshTagList() {
      this.searchTagList = [];
      this.$refs.searchBar && this.$refs.searchBar.handleClearSelect();
      this.$refs.searchBar.noneTagState = false;
      this.getTagList();
      this.getDataList();
    },
  },
};
</script>

<style lang="less" scoped>
// .picture-library {
// padding: 20px;
// background-color: #f0f2f5 !important;
// }
</style>
