<template>
  <div>
    <a-modal v-model="visible" @cancel="onClose" :footer="null" width="700px">
      <div slot="title">
        <span>编辑标签</span>
        <span class="sub-title">右键可修改标签</span>
      </div>
      <a-tree :tree-data="treeData" :replaceFields="{ title: 'name' }" :show-icon="true">
        <template #title="data">
          <a-icon slot="icon" type="folder-open" style="margin-right: 5px" v-show="data.tagLevel < 3" />
          <a-dropdown :trigger="['contextmenu']">
            <span>{{ data.tagName }}</span>
            <template #overlay>
              <a-menu @click="(e) => onContextMenuClick(e, data)">
                <a-menu-item key="1">重命名</a-menu-item>
                <a-menu-item key="2" v-show="data.tagLevel < 3">+ 子标签</a-menu-item>
                <a-menu-item key="3">+ 同级标签</a-menu-item>
                <a-popconfirm
                  placement="right"
                  ok-text="删除"
                  cancel-text="取消"
                  style="text-align: center; cursor: pointer"
                  @confirm.stop="handleTagDel(data)"
                >
                  <template slot="title">
                    <p>确认删除标签?</p>
                  </template>
                  <a-menu-item key="4">- 删除标签</a-menu-item>
                </a-popconfirm>
              </a-menu>
            </template>
          </a-dropdown>
        </template>
      </a-tree>
      <!-- <div>
        <a-tag
          size="large"
          v-for="(item, index) in tagList"
          :key="item.id"
          v-model="item.visible"
          color="blue"
          style="margin-bottom: 10px; padding: 4px 8px"
          @dblclick="onDblclick(item)"
        >
          {{ item.tagName }}
          <a-icon type="loading" v-if="item.loading" />
          <a-icon type="close" @click="deleteTag(index)" v-else />
        </a-tag>
      </div> -->
      <!-- <div style="margin-top: 15px">
        <a-tag v-if="showAddTag" style="border-style: dashed" @click="showAddTag = false">
          <a-icon type="plus" />
          标签
        </a-tag>
        <div v-else class="tag-add">
          <a-input class="tag-input" v-model.trim="addTagText" placeholder="请输入标签"></a-input>
          <a-button class="tag-btn" type="primary" @click="handleTagOk" :loading="addLoading">新增</a-button>
        </div>
      </div> -->
    </a-modal>
    <a-modal
      :title="modaleName"
      v-model="editVisible"
      width="350px"
      :confirmLoading="editTagLoading"
      @ok="updateTagName"
      @cancel="handleEditVisibleClose"
    >
      <a-input v-model.trim="editTag.tagName" placeholder="标签名称" />
    </a-modal>
  </div>
</template>

<script>
import _ from 'lodash';
import api from '@/api/xhsAgencyApi';
import { trackRequest } from '@/utils/track';

export default {
  name: 'TagDeleteModal',
  props: {
    treeData: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      visible: false,
      tagList: [],
      showAddTag: true,
      addTagText: '',
      addLoading: false,
      editVisible: false,
      editPlaceholder: '',
      editTag: {},
      editTagLoading: false,
      modaleName: '',
      eventState: 1, // 1-重命名 2-加子标签 3-加同级标签 4-删除标签
    };
  },
  methods: {
    onDblclick(item) {
      this.editTag = { ...item };
      this.editPlaceholder = item.tagName;
      this.editVisible = true;
    },
    updateTagName() {
      if (!this.editTag.tagName) {
        this.$message.warning('标签名称不能为空!');
        return;
      }

      this.editTagLoading = true;
      const apiName = this.eventState == 1 ? 'renameTagTreeNode' : 'addTagTreeNode';
      api[apiName](this.editTag)
        .then(({ code, message }) => {
          if (code === 200) {
            this.$message.success('操作成功');
            this.$emit('refreshTagList');
            this.editVisible = false;
          } else {
            this.$message.error(`${message}`);
          }
        })
        .finally(() => {
          this.editTagLoading = false;
        });

      // const payload = {
      //   id: this.editTag.id,
      //   tagName: this.editTag.tagName,
      // };
      // this.editTagLoading = true;
      // api
      //   .updateTagApi(payload)
      //   .then(async (res) => {
      //     if (res.code === 200) {
      //       await this.$parent.handleRequest('getTagList', (data) => {
      //         this.showAddTag = true;

      //         this.tagList = _.cloneDeep(data.list).map((item) => {
      //           return {
      //             ...item,
      //             visible: true,
      //             loading: false,
      //           };
      //         });
      //       });
      //       this.handleEditVisibleClose();
      //     } else {
      //       this.$message.warning(res.message);
      //     }
      //   })
      //   .finally(() => {
      //     this.editTagLoading = false;
      //   });
    },
    handleEditVisibleClose() {
      this.editTag = {};
      this.editPlaceholder = '';
      this.editVisible = false;
    },
    openModal(allTagList) {
      this.visible = true;
      if (allTagList.length) {
        this.tagList = _.cloneDeep(allTagList).map((item) => {
          return {
            ...item,
            visible: true,
            loading: false,
          };
        });
      } else {
        this.tagList = [];
      }
    },
    async deleteTag(index) {
      this.$set(this.tagList[index], 'loading', true);
      try {
        const { code, message } = await api.deleteTag(this.tagList[index].id);
        if (code === 200) {
          this.tagList[index].visible = false;
          this.$message.success(`删除成功`);
        } else {
          this.$message.error(`${message}`);
        }
      } catch (error) {
        this.tagList[index].loading = false;
      }
    },
    async handleTagOk() {
      if (this.addTagText) {
        this.addLoading = true;
        const params = {
          tagName: this.addTagText,
        };
        await this.$parent
          .handleRequest(
            'handleAddTag',
            async () => {
              this.addTagText = undefined;
              await this.$parent.handleRequest('getTagList', (data) => {
                this.showAddTag = true;
                this.tagList.unshift({
                  ...data.list[0],
                  visible: true,
                  loading: false,
                });
              });
            },
            params
          )
          .finally(() => (this.addLoading = false));
      } else {
        this.showAddTag = true;
      }
    },
    handleAddTagClick() {
      this.showHashTag = true;
    },
    onClose() {
      // this.$emit(
      //   'tagListChange',
      //   this.tagList.filter((item) => item.visible)
      // );
      this.visible = false;
    },
    handleTagDel({ id }) {
      this.handleTrack('delete_image_tag')

      api.delTagTreeNode(id).then(({ code, message }) => {
        if (code === 200) {
          this.$message.success('删除成功');
          this.$emit('refreshTagList');
        } else {
          this.$message.error(`${message}`);
        }
      });
    },
    onContextMenuClick({ key: eventState }, data) {
      this.eventState = eventState;
      this.editTag = { id: '' };
      if (eventState === '1') {
        this.modaleName = '重命名标签';
        this.editTag.id = data.id;
        this.editTag.tagName = data.name;
      }
      if (eventState === '2') {
        this.modaleName = '添加子标签';
        this.editTag.parentId = data.id;
        this.handleTrack('add_image_tag')
      }
      if (eventState === '3') {
        this.modaleName = '添加同级标签';
        this.editTag.parentId = data.tagLevel == 1 ? '' : data.parentId;
        this.handleTrack('add_image_tag')
      }
      this.editVisible = true;
    },
    handleTrack(trackEvent, trackParams = {}) {
      const to = this.$route;
      trackRequest(to, trackEvent, trackParams);
    },
  },
};
</script>

<style scoped lang="less">
.sub-title {
  margin-left: 8px;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.45);
}

.tag-add {
  display: flex;
  margin-bottom: 10px;
  align-items: center;

  .tag-input {
    width: 120px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .tag-btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

/deep/.ant-dropdown-menu-item {
  text-align: center;
}
</style>
