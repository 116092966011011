<template>
  <div class="upload-btn">
    <a-button
      @click="
        () => {
          showModal = true;
          handleTrack('upload_image');
        }
      "
      type="primary"
      >上传图片</a-button
    >
    <a-modal v-model="showModal" title="上传图片" :width="800" @close="handleCancel" @cancel="handleCancel">
      <a-upload-dragger
        name="file"
        :multiple="true"
        accept=".jpg, .png, .jpeg"
        :file-list="file_list"
        :customRequest="customRequest"
        :beforeUpload="beforeUploadImg"
        @change="handleChange"
        :disabled="isUploading"
      >
        <p class="ant-upload-drag-icon">
          <a-icon type="inbox" />
        </p>
        <p class="ant-upload-text">点击或将文件拖拽到这里上传</p>
        <p class="ant-upload-hint">支持扩展名：.png .jpg .jpeg</p>
        <p class="ant-upload-hint">单次上传最大50张</p>
      </a-upload-dragger>
      <div style="margin: 10px; color: #a8a8a8">共{{ this.file_list.length || 0 }}张图片</div>
      <div class="tag-line">
        <div class="tag-title"><span style="color: red">*</span>标签：</div>
        <div class="tag-line">
          <template v-if="showModal">
            <CascaderMenu
              ref="cascaderMenu"
              :option="item"
              v-for="(item, index) in tagTree"
              :key="index"
              @change="(props) => handleTagChange(props, index)"
            />
          </template>
        </div>
        <!-- <div class="tag-body">
          <div
            :class="['tags', { active: value.isChoose }]"
            v-for="value in tagList"
            :key="value.id"
            @click="handleTagClick(value.id)"
          >
            {{ value.tagName }}
          </div>
          <a-button size="small" style="margin-bottom: 10px" v-show="showAddTag" @click="showAddTag = false"
            >+添加</a-button
          >
          <div v-show="!showAddTag" class="tag-add">
            <a-input class="tag-input" v-model.trim="addTagText" placeholder="请输入标签"></a-input>
            <a-button class="tag-btn" type="primary" @click="handleTagOk" :loading="addLoading">确认</a-button>
          </div>
        </div> -->
      </div>
      <div class="bottom">
        <div style="width: 45px px; flex-shrink: 0">已选：</div>
        <div class="tag-list">
          <div class="tags" v-for="value in tagList" :key="value.id">
            <span>{{ value.parentText }}</span
            ><span style="color: #1890ff">{{ value.text }}</span>
            <span>
              <a-icon type="close" style="margin-left: 5px; cursor: pointer" @click="handleTagDel(value)" />
            </span>
          </div>
        </div>
      </div>

      <a-form-model
        ref="form"
        class="form-wrapper"
        :model="form"
        v-bind="{ labelCol: { span: 4 }, wrapperCol: { span: 20 } }"
      >
        <a-row style="margin-top: 10px">
          <a-col :span="11">
            <a-form-model-item label="品牌" required>
              <a-select
                mode="multiple"
                v-model="form.brand"
                placeholder="请选择"
                show-search
                option-filter-prop="children"
                @change="handleBrandChange"
                :maxTagCount="2"
                allowClear
              >
                <a-select-option v-for="item in brandList" :key="`${item.id},${item.principalName}`">{{
                  item.principalName
                }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="13">
            <a-form-model-item label="车系" required>
              <a-select
                mode="multiple"
                v-model="form.type"
                placeholder="请选择"
                show-search
                option-filter-prop="children"
                :maxTagCount="2"
                :loading="typeLoading"
                allowClear
              >
                <a-select-option v-for="item in typeList" :key="`${item.id},${item.name}`">{{
                  item.name
                }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="11">
            <a-form-model-item label="门店">
              <store-select-input
                style="width: 100%"
                :principalIds="form.brand"
                v-model="form.memberInfoList"
                placeholder="请选择门店"
                show-search
                mode="multiple"
                :filter-option="false"
                :maxTagCount="1"
                :hasName="true"
                allowClear
              ></store-select-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="13">
            <a-form-model-item label="账号">
              <account-select-input
                style="width: 100%"
                :dealerIds="memberIdList"
                v-model="form.authorInfoList"
                placeholder="请选择账号"
                show-search
                mode="multiple"
                :filter-option="false"
                :maxTagCount="1"
                allowClear
              ></account-select-input>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <template slot="footer">
        <a-row type="flex" justify="center">
          <a-col :span="3">
            <a-button type="primary" :loading="btnLoading" @click="handleOk"> 确认上传 </a-button>
          </a-col>
          <a-col :span="3">
            <a-button @click="handleCancel"> 退出 </a-button>
          </a-col>
        </a-row>
      </template>
    </a-modal>
  </div>
</template>

<script>
import SparkMD5 from 'spark-md5';
import ossUploadMixin from '@/components/BaseUpload/ossUploadMixin.js';
import CascaderMenu from '@/components/CascaderMenu.vue';
import TopicTreeInput from '@/components/xhsAgencyTool/TopicTreeInput';
import { trackRequest } from '@/utils/track';

import StoreSelectInput from '@/components/xhsAgencyTool/StoreSelectInput';
import AccountSelectInput from '@/components/xhsAgencyTool/AccountSelectInput';

export default {
  components: { CascaderMenu, TopicTreeInput, StoreSelectInput, AccountSelectInput },
  mixins: [ossUploadMixin],
  props: {
    // tagList: {
    //   type: Array,
    //   default: () => [],
    // },
    brandList: {
      type: Array,
      default: () => [],
    },
    deal: {
      default: 0,
    },
    tagTree: {
      default: [],
    },
  },
  data() {
    return {
      isAll: true,
      showModal: false,
      btnLoading: false,
      typeLoading: false,
      file_list: [],
      showAddTag: true,
      addLoading: false,
      addTagText: '',
      typeList: [],
      form: {
        brand: undefined,
        type: undefined,
        selectionTagList: [],
        memberInfoList: [],
        authorInfoList: [],
      },
      uploadParamsData: {
        appId: 'bfz',
        filePath: '/bfz/xhs/', ///bfz/xhs/
      },
      tagList: [],
    };
  },
  computed: {
    isUploading() {
      let hasLoading = false;
      this.file_list.forEach((v) => {
        if (v.status === 'uploading') {
          hasLoading = true;
        }
      });
      return hasLoading;
    },
    memberIdList() {
      return this.form.memberInfoList.map((val) => {
        const parm = val.split(',');
        return parm[0];
      });
    },
  },
  methods: {
    async handleBrandChange(id) {
      if (id.length) {
        const principalIds =
          id.reduce((prev, item) => {
            prev += `,${item}`;
            return prev;
          }) || '';
        this.typeLoading = true;
        this.typeList = [];
        this.$parent
          .handleRequest('getTypeList', (data) => (this.typeList = data), { principalIds })
          .finally(() => (this.typeLoading = false));
      } else {
        this.typeList = [];
      }
    },
    async handleTagOk() {
      if (this.addTagText) {
        this.addLoading = true;
        const params = {
          tagName: this.addTagText,
        };
        await this.$parent
          .handleRequest(
            'handleAddTag',
            () => {
              this.$message.success('添加成功');
              this.addTagText = undefined;
              this.$parent.handleRequest('getTagList', (data) => {
                this.$emit('handleAddTag', data.list[0]);
                this.showAddTag = true;
              });
            },
            params
          )
          .finally(() => (this.addLoading = false));
      } else {
        this.showAddTag = true;
      }
    },
    handleTagClick(id) {
      this.tagList.forEach((value) => {
        if (value.id === id) {
          value.isChoose = !value.isChoose;
        }
      });
    },
    beforeUploadImg(file, fileList) {
      // const isLt2M = file.size / 1024 / 1024 < 2;
      // if (!isLt2M) {
      //   this.$message.error('Image must smaller than 2MB!');
      //   return false
      // }
      // 限制上传数量
      const index = fileList.findIndex((ele) => ele.uid == file.uid);
      if (fileList.length > 50 && index >= 50) return false;
      return new Promise((resolve) => {
        let fileType = /\.(jpeg|jpg|png)$/i;
        if (!fileType.test(file.name)) {
          this.$message.error('仅支持图片文件');
          return false;
        }
        let fileReader = new FileReader();
        let spark = new SparkMD5.ArrayBuffer(); //获取文件二进制数据
        fileReader.readAsArrayBuffer(file); //file就是获取到的文件 //异步执行函数
        const filePath = '/bfz/xhs/';
        fileReader.onload = function (e) {
          spark.append(e.target.result);
          let md5 = spark.end();
          file.md5 = md5;
          file.params_data = {
            appId: 'bfz',
            filePath,
            identifier: file.md5,
            name: file.name,
            title: file.name.split('.')[0],
          };
          return resolve(true);
        };
      });
    },
    async customRequest(option) {
      const { file, onSuccess } = option;
      await this.normalUploadFile(file, onSuccess, true);
    },
    handleChange(info) {
      if (info.file.status) {
        this.file_list = info.fileList;

        if (info.file.status === 'uploading') {
          this.file_list.forEach((val) => {
            if (val.fileId === info.file.fileId) {
              val.status = 'uploading';
            }
          });
        }

        if (info.file.status === 'done') {
          let fileList = [...info.fileList];
          fileList = fileList.map((file) => {
            if (file.response) {
              file.fileId = file.response.data.data.id;
              file.url = file.response.url;
              file.status = 'done';
            }
            return file;
          });
          this.file_list = fileList;
        }

        if (info.file.status === 'error') {
          this.$message.error(`${info.file.name} 文件上传失败`);
        }
      } else {
        return;
      }
    },
    async handleOk() {
      // 判断图片
      const fileInfoList = [];
      let hasLoading = false;
      this.file_list?.length &&
        this.file_list.forEach((v) => {
          if (v.status === 'uploading') {
            hasLoading = true;
          }
          if (v.status === 'done') {
            fileInfoList.push({
              fileId: v.fileId,
              fileUrl: v.url,
            });
          }
        });

      if (hasLoading) return this.$message.error('请等待全部图片上传完成');
      if (!fileInfoList?.length) return this.$message.error('请至少上传一张图片');
      // 处理标签字段
      let tagList = [];
      if (this.tagList?.length) {
        tagList = this.tagList.map((val) => ({
          tagId: val.id,
          tagName: val.text,
        }));
      } else {
        tagList = [];
      }
      if (tagList.length === 0) {
        this.$message.error('请至少选择一个标签');
        return;
      }

      let selectionTagList = this.form.selectionTagList;
      // 处理品牌字段
      let principalInfoList = [];
      if (this.form.brand?.length) {
        principalInfoList = this.form.brand.map((val) => {
          const parm = val.split(',');
          return {
            principalId: parm[0],
            principalName: parm[1],
          };
        });
      } else {
        principalInfoList = [];
      }

      if (!principalInfoList.length) {
        this.$message.error('请至少选择一个品牌');
        return;
      }

      // 处理车系字段
      let vehicleSeriesInfoList = [];
      if (this.form.type?.length) {
        vehicleSeriesInfoList = this.form.type.map((val) => {
          const parm = val.split(',');
          return {
            vehicleSeriesId: parm[0],
            vehicleSeriesName: parm[1],
          };
        });
      } else {
        vehicleSeriesInfoList = [];
      }

      if (!vehicleSeriesInfoList.length) {
        this.$message.error('请至少选择一个车系');
        return;
      }

      // 处理门店
      let memberInfoList = [];
      if (this.form.memberInfoList.length) {
        memberInfoList = this.form.memberInfoList.map((val) => {
          const parm = val.split(',');
          return {
            memberId: parm[0],
            memberName: parm[1],
          };
        });
      } else {
        memberInfoList = [];
      }

      // 处理账号
      let authorInfoList = [];
      if (this.form.authorInfoList.length) {
        authorInfoList = this.form.authorInfoList.map((val) => {
          const parm = val.split(',');
          return {
            authorId: parm[0],
            nickname: parm[1],
          };
        });
      } else {
        authorInfoList = [];
      }

      const params = {
        fileInfoList,
        principalInfoList,
        vehicleSeriesInfoList,
        tagList,
        selectionTagList,
        memberInfoList,
        authorInfoList,
      };

      this.btnLoading = true;
      await this.$parent
        .handleRequest(
          'addLibraryPic',
          () => {
            this.$message.success('操作成功');
            this.handleCancel();
            this.$parent.getDataList();
          },
          params
        )
        .finally(() => (this.btnLoading = false));
    },
    handleCancel() {
      this.addTagText = '';
      this.file_list = [];
      this.typeList = [];
      this.tagList = [];
      // const newTagList = this.tagList.map((val) => {
      //   val.isChoose = false;
      //   return val;
      // });
      // this.$emit('update:tagList', newTagList);
      // this.tagList.forEach(val => {
      //     val.isChoose = false
      // })
      this.showModal = false;
      this.form = {
        brand: undefined,
        type: undefined,
        selectionTagList: [],
        memberInfoList: [],
        authorInfoList: [],
      };
    },
    handleTagChange(value, compIndex) {
      if (value.isAdd) {
        console.log(value, '999');
        const treeData = this.tagTree[compIndex];
        const parentText = `${treeData.name} > ${value.parent.name} > `;
        const text = value.self.name;
        this.tagList.push({
          id: value.self.id,
          parentText,
          text,
          tagPath: value.self.tagPath,
          compIndex,
        });
      } else {
        const index = this.tagList.indexOf((ele) => ele.id === value.self.id);
        this.tagList.splice(index, 1);
      }
    },
    handleTagDel(value) {
      this.$refs.cascaderMenu[value.compIndex].delSelect(value.tagPath);
      let tagIndex = -1;
      this.tagList.forEach((ele, index) => {
        if (ele.id == value.id) {
          tagIndex = index;
        }
      });
      this.tagList.splice(tagIndex, 1);
    },
    handleTrack(trackEvent, trackParams = {}) {
      const to = this.$route;
      trackRequest(to, trackEvent, trackParams);
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .form-wrapper .ant-form-item {
  margin-bottom: 8px;
}

.tag-line {
  display: flex;

  .tag-title {
    padding-top: 5px;
    width: 63px;
    color: #262626;
  }

  .tag-body {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    color: #2d2d2d;
    align-items: center;

    .tags {
      padding: 5px 10px;
      margin: 0 10px 10px;
      border-radius: 6px;
      cursor: pointer;
    }

    .active {
      background-color: #1890ff;
      color: #fff;
    }

    .tag-add {
      display: flex;
      margin-bottom: 10px;
      align-items: center;

      .tag-input {
        width: 120px;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      .tag-btn {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }
}

.bottom {
  display: flex;
  margin-top: 10px;
  margin-bottom: 10px;
}

.tag-list {
  display: flex;
  flex-wrap: wrap;

  .tags {
    margin: 0 5px 5px 0;
    padding: 0 5px;
    border: 1px solid #1890ff;
    border-radius: 3px;
    color: #80c2ff;
    user-select: none;
  }
}
</style>
