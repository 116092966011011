<template>
  <div class="search">
    <div class="search-top">
      <a-form-model ref="form" :model="form" labelAlign="left" :labelCol="{ xl: 6, xxl: 5 }" :wrapperCol="{ span: 15 }">
        <a-row class="search-bottom">
          <a-col :span="6">
            <a-form-model-item label="品牌">
              <a-select
                class="item-select"
                mode="multiple"
                v-model="form.principalIds"
                placeholder="请选择"
                show-search
                option-filter-prop="children"
                @change="handleBrandChange"
                :maxTagCount="2"
                allowClear
              >
                <a-select-option v-for="item in brandList" :key="item.id">{{ item.principalName }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="车系">
              <a-select
                style="width: 100%"
                mode="multiple"
                v-model="form.vehicleSeriesIds"
                placeholder="请选择车系"
                show-search
                :filter-option="false"
                :maxTagCount="2"
                :loading="typeLoading"
                allowClear
                @search="getTypeList"
                @change="handleTypeChange"
              >
                <a-spin v-if="typeLoading" slot="notFoundContent" size="small" />
                <a-select-option v-else v-for="item in typeList" :key="`${item.id},${item.name}`">{{
                  item.name
                }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="添加人">
              <a-select
                v-model="form.creator"
                placeholder="请选择添加人"
                show-search
                option-filter-prop="children"
                @change="handleTypeChange"
                allowClear
              >
                <a-select-option v-for="item in creatorList" :key="item.username" :value="item.username">{{ item.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="上传时间">
              <a-range-picker v-model="upTime" @change="onChangeDate" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-row class="search-bottom">
          <a-col :span="6">
            <a-form-model-item label="门店">
              <store-select-input
                style="width: 100%"
                :principalIds="form.principalIds"
                v-model="form.dealerIds"
                placeholder="请选择门店"
                show-search
                mode="multiple"
                :filter-option="false"
                :maxTagCount="1"
                allowClear
                @blur="handleStoreChange"
                @deselect="handleStoreChange"
              ></store-select-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="账号">
              <account-select-input
                style="width: 100%"
                :dealerIds="form.dealerIds"
                v-model="form.authorIds"
                placeholder="请选择账号"
                show-search
                mode="multiple"
                :filter-option="false"
                :maxTagCount="1"
                allowClear
                @blur="handleAccountChange"
                @deselect="handleAccountChange"
              ></account-select-input>
            </a-form-model-item>
          </a-col>
          <a-col :span="6">
            <a-form-model-item label="已使用">
              <a-select
                class="item-select"
                v-model="form.editorCount"
                placeholder="请选择"
                allowClear
                @change="handleTypeChange"
              >
                <a-select-option :value="0">未使用</a-select-option>
                <a-select-option :value="1">已使用</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
      <div class="search-top-bar">
        <div style="display: flex">
          <div class="tag-head">标签：</div>
          <CascaderMenu
            ref="cascaderMenu"
            :option="item"
            v-for="(item, index) in tagTree"
            :key="index"
            :multiple="false"
            @change="(val) => handleTagChange(val, index)"
          />
          <div :class="['none-tag', { 'active-none': noneTagState }]" @click="handleNoneTagClick">无标签</div>
        </div>
        <!-- <div class="tag-body" :class="{ open: isOpen }">
          <div
            :class="['tags', { active: value.isChoose }]"
            v-for="value in tagList"
            :key="value.id"
            @click="handleTagClick(value.id)"
          >
            {{ value.tagName }}
          </div>
        </div> -->
        <!-- <a-button type="link" @click="toggleContainer">{{ isOpen ? '收起' : '展开' }}</a-button> -->
        <a-button type="danger" @click="handleDeleteTag" ghost>编辑标签</a-button>
      </div>
    </div>
    <div class="search-bottom">
      <a-tabs default-active-key="0" @change="handleTabChange" size="large">
        <a-tab-pane :key="0" tab="成品"> </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>

<script>
import debounce from 'lodash/debounce';
import CascaderMenu from '@/components/CascaderMenu.vue';
import TopicTreeInput from '@/components/xhsAgencyTool/TopicTreeInput';
import StoreSelectInput from '@/components/xhsAgencyTool/StoreSelectInput';
import { trackRequest } from '@/utils/track';
import AccountSelectInput from '@/components/xhsAgencyTool/AccountSelectInput';

export default {
  components: { CascaderMenu, TopicTreeInput, StoreSelectInput, AccountSelectInput },
  props: {
    tagList: {
      type: Array,
      default: () => [],
    },
    tagTree: {
      type: Array,
      default: () => [],
    },
    brandList: {
      type: Array,
      default: () => [],
    },
    creator: {
      type: String,
    },
  },
  data() {
    this.getTypeList = debounce(this.getTypeList, 500);
    return {
      deal: undefined, // 0 - AIGC 1 - 已处理
      uploadTag: [],
      upTime: [],
      form: {
        principalIds: [],
        vehicleSeriesIds: [],
        dealerIds: [],
        authorIds: [],
        creator: undefined,
        editorCount: undefined,
        pushStatus: undefined,
        selectionTagList: [],
      },
      creatorList: [],
      typeList: [],
      isOpen: false,
      typeLoading: false,
      checkTagList: [],
      noneTagState: false,
    };
  },
  created() {
    this.form.creator = this.creator;
    this.getCreatorList();
  },
  methods: {
    disabledDate(current) {
      // Can not select days before today and today
      return current && current > this.$moment().endOf('day');
    },
    onPushStatusChange() {
      this.$emit('handlePushStatusChange', this.form);
    },
    onChangeDate() {
      if (this.upTime[0] && this.upTime.length > 0) {
        this.form.startDateTime = this.$moment(this.upTime[0]._d).format('YYYY-MM-DD') + ' 00:00:00';
        this.form.endDateTime = this.$moment(this.upTime[1]._d).format('YYYY-MM-DD') + ' 23:59:59';
      } else {
        this.form.startDateTime = undefined;
        this.form.endDateTime = undefined;
      }
      this.$emit('handleDateChange', this.form);
    },
    handleTopicChange(val) {
      this.form.selectionTagList = val;
      this.$emit('handleTopicChange', this.form);
    },
    async getCreatorList() {
      this.$parent.handleRequest('getPictureCreatorList', (data) => {
        this.creatorList = data;
      });
    },
    getTypeList(filter) {
      filter = filter ? filter.trim() : '';
      // 是否存在品牌
      const params = {};
      if (this.form.principalIds && this.form.principalIds.length) {
        const principalIds =
          this.form.principalIds.reduce((prev, item) => {
            prev += `,${item}`;
            return prev;
          }) || '';
        params.principalIds = principalIds;
        // 是否筛选车系
        if (filter) {
          // 筛选
          params.filter = filter;
        }
      } else {
        // 不选择品牌，搜索全部车系
        this.typeList = [];
        // 是否筛选车系
        if (filter) {
          // 筛选
          params.filter = filter;
        } else {
          // 不筛选车系
          this.typeList = [];
          return;
        }
      }
      this.typeLoading = true;
      this.typeList = [];
      this.$parent
        .handleRequest(
          'getTypeList',
          (data) => {
            this.typeList = data;
          },
          params
        )
        .finally(() => (this.typeLoading = false));
    },
    toggleContainer() {
      this.isOpen = !this.isOpen;
    },
    handleTagClick(id) {
      this.$emit('handleTagClick', id);
    },
    handleBrandChange(val) {
      this.$emit('handleBrandChange', val, this.form);
      this.getTypeList();
    },
    handleTypeChange() {
      this.$emit('handleTypeChange', this.form);
    },
    handleStoreChange() {
      this.$nextTick(() => {
        this.$emit('handleStoreChange', this.form);
      });
    },
    handleAccountChange() {
      this.$nextTick(() => {
        this.$emit('handleAccountChange', this.form);
      });
    },
    handleCreatorChange(e) {
      e.type === 'click' && this.handleTypeChange();
    },
    handleTabChange(val) {
      this.$emit('handleTabChange', val);
    },
    handleDeleteTag() {
      this.$emit('handleDeleteTag');
      this.handleTrack('click_edit_tags_btn');
    },
    handleJumpTemplate() {
      window.open(process.env.VUE_APP_TEMPLATE_EDITOR, '_blank');
    },
    handleJumpAIDrawing() {
      const url = this.$router.resolve({
        path: '/xhsAgency/AIDrawing',
      });
      window.open(url.href, '_blank');
    },
    handleTagChange(changeData, index) {
      this.noneTagState = false;
      // 标签搜索改单选
      this.handleClearSelect(index);
      if (changeData.isAdd) {
        this.checkTagList = [];
        this.checkTagList.push(changeData.self.id);
      } else {
        const findIndex = this.checkTagList.indexOf(changeData.self.id);
        this.checkTagList.splice(findIndex, 1);
      }
      this.$emit('handleTagChange', this.checkTagList);
    },
    handleNoneTagClick() {
      this.noneTagState = !this.noneTagState;
      this.handleClearSelect();
      this.checkTagList = [];
      if (this.noneTagState) {
        this.$emit('handleTagChange', ['1236389243540656128']); // 查询无标签
      } else {
        this.$emit('handleTagChange', []);
      }
    },
    handleClearSelect(index) {
      if (index >= 0) {
        // 标签搜索改单选 清空自身外选择
        this.$refs.cascaderMenu &&
          this.$refs.cascaderMenu.forEach((val, i) => {
            if (i !== index) {
              val.clearAll();
            }
          });
      } else {
        this.$refs.cascaderMenu &&
          this.$refs.cascaderMenu.forEach((val) => {
            val.clearAll();
          });
      }
    },
    handleTrack(trackEvent, trackParams = {}) {
      const to = this.$route;
      trackRequest(to, trackEvent, trackParams);
    },
  },
};
</script>

<style lang="less" scoped>
::v-deep .search-bottom .ant-form-item {
  margin-bottom: 10px;
}

.search {
  border-radius: 5px;
}

.search-top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .tag-head {
    // padding-top: 5px;
    margin-right: 30px;
    height: 27px;
    line-height: 27px;
    color: #000;
  }

  .tag-body {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    color: #2d2d2d;
    max-height: 40px;
    overflow: hidden;
    transition: max-height 0.5s;
    &.open {
      max-height: 300px;
    }
    .tags {
      padding: 5px 10px;
      margin: 0 10px 10px;
      border-radius: 6px;
      cursor: pointer;
    }

    .active {
      background-color: #1890ff;
      color: #fff;
    }
  }
}
.search-bottom {
  position: relative;
}
.action-content {
  position: absolute;
  right: 0;
  top: 23px;
  width: 290px;
  display: flex;
  justify-content: space-between;
}
.item-select {
  width: 100%;
}
.none-tag {
  cursor: pointer;
  height: 27px;
  line-height: 27px;
}

.active-none {
  color: #1890ff;
}
</style>
